<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <h4 slot="header" class="card-title">
          Add/Edit Product
        </h4>

        <fg-input label="Name" placeholder="SB-36 18' White"></fg-input>
        <fg-input label="Cost" placeholder="200"></fg-input>
        <fg-input label="Stock" placeholder="12"></fg-input>
        <fg-input label="Hours" placeholder="0.5"></fg-input>
        <fg-input label="Category" placeholder="Will be drop-down of categories"></fg-input>

        <p-button type="info" round>Submit</p-button>

      </card> <!-- end card -->
    </div> <!--  end col-md-6  -->
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents'

  export default {
    components: {
      Card
    },
    data() {
      return {
        radios: {
          radio1: '1',
          radio2: '2',
          radio3: '2'
        },
        checkboxes: {
          first: false,
          second: false,
          a: false,
          b: false,
          c: false,
          unchecked: false,
          checked: true,
          disabledUnchecked: false,
          disabledChecked: true
        }
      }
    }
  }
</script>
<style>
</style>
