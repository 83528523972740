<template>
  <div>
    <div class="card card-map">
      <div class="card-header">
        <h4 class="card-title">Active Job Locations</h4>
      </div>
      <div id="map" class="full-screen-map"></div>
    </div>
  </div>
</template>
<script>
import { API_KEY } from "./API_KEY";
import { Loader, LoaderOptions } from "google-maps";

const loader = new Loader(API_KEY);

  export default {
    mounted () {
      loader.load().then(function(google) {
        // Regular Map
        var myLatlng = new window.google.maps.LatLng(33.443422, -112.1470595)
        var mapOptions = {
          zoom: 11,
          center: myLatlng,
          scrollwheel: false // we disable de scroll over the map, it is a really annoing when you scroll through page
        }

        var map = new window.google.maps.Map(document.getElementById('map'), mapOptions)

        var marker = new window.google.maps.Marker({
          position: myLatlng,
          title: 'Hart Carport Solutions',
          icon: '/static/img/icons/home-icon-1.svg'
        })

        marker.setMap(map)

        var job1Marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(33.4089077, -112.0037635),
          title: 'Broadway Industrial - 3/21/2022'
        })

        job1Marker.setMap(map)

        var job2Marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(33.5544712, -112.1131577),
          title: 'Akaihana - 3/5/2022'
        })

        job2Marker.setMap(map)
      });
    }
  }
</script>
<style>
</style>
