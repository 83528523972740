<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Labor Actions</h5>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table :data="tableData" header-row-class-name="text-primary">
            <el-table-column prop="name"
                             label="Name">
            </el-table-column>
            <el-table-column prop="hours"
                             label="Hours">
            </el-table-column>
            <el-table-column prop="cost"
                             label="Cost">
            </el-table-column>
            <el-table-column prop="price"
                             label="Default Price">
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label="Actions">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import Swal from 'sweetalert2';
  import {Table, TableColumn} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    components: {
      PSwitch
    },
    data () {
      return {
        tableData: [{
          name: 'Replace',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }, {
          name: 'Piece In',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }, {
          name: 'Patch',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }, {
          name: 'Re-Attach',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }, {
          name: 'Straighten Beam 85-95%',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }, {
          name: 'Wrap Corner',
          hours: '0.25',
          cost: '$20',
          price: '$40'
        }],
        productsTable: [
          {
            image: 'static/img/tables/agenda.png',
            title: 'Notebook',
            subTitle: 'Most beautiful agenda for the office.',
            price: 49,
            quantity: 1
          },
          {
            image: 'static/img/tables/stylus.jpg',
            title: 'Stylus',
            subTitle: 'Design is not just what it looks like and feels like. Design is how it works.',
            price: 499,
            quantity: 2
          },
          {
            image: 'static/img/tables/evernote.png',
            title: 'Evernote iPad Stander',
            subTitle: 'A groundbreaking Retina display. All-flash architecture. Fourth-generation Intel processors.',
            price: 799,
            quantity: 1
          }
        ]
      }
    },
    methods: {
      handleLike (index, row) {
        alert(`Your clicked on Like button`)
      },
      handleEdit (index, row) {
        alert(`Your want to edit ${row.name}`)
      },
      handleDelete (index, row) {
        const swalWithBootstrapButtons4 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons4
          .fire({
            title: `Are you sure you want to?`,
            text: `${row.name} will be permanently deleted!`,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              const swalWithBootstrapButtons5 = Swal.mixin({
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
              swalWithBootstrapButtons5.fire({
                title: "Deleted!",
                text: `${row.name} has been deleted.`,
              });
            }
          });
      },
      getSummaries (param) {
        const { columns } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total'
          } else if (index < columns.length - 1) {
            sums[index] = ''
          } else {
            let sum = 0
            this.productsTable.forEach((obj) => {
              sum += obj.quantity * obj.price
            })
            sums[index] = `€ ${sum}`
          }
        })
        return sums
      }
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
